<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    经营设置 - 行政区设置
-->
<style lang="less">
</style>

<template>
  <Form>
    <FormItem
      v-for="(item, i) in inputItems"
      :key="i"
      :label="item.label + ':'"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        v-if="item.tagName === 'Input'"
        v-model.trim="model[item.key]"
        :placeholder="item.placeholder"
        class="display-flex-1"
        @input="checkSubmit"
      />
      <!--省，市，区，街道-->
      <yg-region-cascader
        v-else-if="item.tagName === 'YgRegionCascader'"
        v-model="model[item.key]"
        :level="item.level"
        :disabled="item.disabled"
        style="width: 100%"
      />
    </FormItem>
  </Form>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      inputItems: [
        { label: '省/市/区', labelWidth: 120, placeholder: '暂无数据', key: 'h5AreaArray', isRequired: true, tagName: 'YgRegionCascader', level: 2 },
        { label: '详细地址', labelWidth: 120, placeholder: '暂无数据', key: 'address', isRequired: true, tagName: 'Input' }
      ]
    };
  },
  created () {
    console.log(this.model.h5AreaArray);
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    // 提交
    checkSubmit () {
      const item = this.inputItems.find(v => !this.model[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    }
  }
};
</script>
