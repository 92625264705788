<!--
    Created by 程雨喵'mac on 2023/12/12.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：组织管理-小区管理
-->
<style lang="less">
</style>

<template>
  <section>
    <!-- 查询条件 -->
    <div v-permission="'system:community:query'">
      <yg-search-area
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      v-permission="'system:community:query'"
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        @on-page-change="onChangePage"
      />
    </div>
    <!--弹窗：新增(基础信息)-->
    <v-base-info-submit
      v-if="!!dialog.baseInfoModel"
      :model="dialog.baseInfoModel"
      :info-type="4"
      @on-close="dialog.baseInfoModel = null"
      @on-sure="onSureBaseInfo"
    />
    <!--弹窗：设置-->
    <v-setting-submit
      v-if="!!dialog.settingModel"
      :index="dialog.settingIndex"
      :model="dialog.settingModel"
      @on-close="onCloseSubmit"
    />
    <v-edit-block
      v-if="!!dialog.editBlockModel"
      :model="dialog.editBlockModel"
      @on-sure="onEditBlockSubmit"
      @on-close="dialog.editBlockModel = null"
    />
  </section>
</template>

<script>
import CommunityApiSet from '@/request/api/CommunityApiSet';
import { BlockListModel } from './model/BlockDataModel';
import ColumnMixin from './mixin/tableColumn';
import vSettingSubmit from './view/setting/index.vue';
import vBaseInfoSubmit from '@/pages/020__organizationManager/_components/baseInfo.vue';
import vEditBlock from './view/setting/editBlock.vue';

export default {
  components: { vBaseInfoSubmit, vSettingSubmit, vEditBlock },
  mixins: [ColumnMixin],
  data () {
    return {
      searchOption: {
        regionCompany: BaseSearchModel.initData('物业区域公司', 'regionCompany', 'YgGlobalSelect').setData('regionalCompanyList').setExclusions(['officeCode', 'region']),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList').setExclusions(['regionCompany', 'region']),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2).setExclusions(['regionCompany', 'officeCode']),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        // communityType: BaseSearchModel.initData('创建类型', 'communityType', 'Select').setData([
        //   { label: '系统同步', value: '1' },
        //   { label: '人工创建', value: '2' }
        // ]),
        time: BaseSearchModel.initData('创建时间', 'time', 'DatePickerRange').setDatePickerElevator([])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('新增', 'add', 'md-add-circle').setPermission('system:community:add')
        // BaseSettingModel.initData('导出列表', 'export', 'md-cloud-download').setExport().setExportReportType(23).setPermission('')
      ],
      page: { current: 1, total: 0 },
      tableData: [],
      dialog: {
        baseInfoModel: null,
        settingModel: null,
        settingIndex: 0,
        editBlockModel: null
      }
    };
  },
  created () {
    // this.requestData();
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    // 新增数据
    onSureBaseInfo () {
      const res = this.dialog.baseInfoModel.checkValidAdditionSubmit();
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAdd(res.params);
      }
    },
    onCloseSubmit (needRefresh) {
      if (needRefresh) {
        this.onChangePage(1);
      }
      this.dialog.settingModel = null;
    },
    // 编辑小区
    onEditBlockSubmit () {
      const res = this.dialog.editBlockModel.checkValidEditSubmit();
      console.log(res);
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestAdd(res.params);
      }
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {
      switch (key) {
      case 'add':
        this.dialog.baseInfoModel = BlockListModel.createAdditionModel();
        break;
      }
    },
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      this.tableData = [];
      this.page.total = 0;
      const api = CommunityApiSet.communityList;
      const s = this.searchOption;
      const time = s.time;
      api.params = {
        startTime: time.value[0] || '',
        endTime: time.value[1] || '',
        pageSize: this.$config.pageSize,
        pageNo: this.page.current,
        orgCode: s.regionCompany.value,
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        communityName: s.communityName.value,
        officeCode: s.officeCode.value
        // communityType: s.communityType.value
      };
      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = BlockListModel.initListWithDataList(resData.rows);
      });
    },
    // 新增、编辑小区
    requestAdd (p) {
      const api = CommunityApiSet.communityAdd;
      api.params = p;
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        if (!api.params.id) {
          this.onChangePage(1);
          this.dialog.baseInfoModel = null;
        } else {
          this.requestData();
          this.dialog.editBlockModel = null;
        }
      });
    },
    // 小区启用禁用状态切换
    requestChangeStatus (row, status) {
      const api = CommunityApiSet.communityChangeStatus;
      api.params = {
        id: row.communityId,
        status: status ? 1 : 0
      };
      this.$http(api).then(res => {
        this.$Message.success('操作成功');
        row.status = status ? 1 : 0;
        row.h5StatusSwitch = status;
      }).catch(e => {
        row.status = status ? 0 : 1;
        row.h5StatusSwitch = !status;
      });
    },
    // 小区详情
    requestDetail (id, modelType) {
      const api = CommunityApiSet.communityInfo;
      api.params = { id };
      this.$http(api).then(res => {
        if (modelType === 3) {
          this.dialog.editBlockModel = BlockListModel.initModelWithData(res.data);
        } else {
          this.dialog.settingModel = BlockListModel.initModelWithData(res.data);
        }
      });
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
