export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('communityName', '小区名称', 160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('communityCode', '小区编码', 130).setSpan({ canCopy: true }),
        BaseTableColumn.initData('concat', '联系人').setWidth(160).setKeyValueList([
          { label: '姓名:', labelWidth: 34, key: 'contact', attr: { canTooltip: true } },
          { label: '手机:', labelWidth: 34, key: 'mobile', attr: { canHidePhone: true } }
        ]),
        BaseTableColumn.initData('propertyCompanyName', '所属\n物业公司', 120).setSpan({}),
        BaseTableColumn.initData('area', '省市区').setKeyValueList([
          { label: '省份:', labelWidth: 50, key: 'provinceName', attr: { canTooltip: true } },
          { label: '城市:', labelWidth: 50, key: 'cityName', attr: { canTooltip: true } },
          { label: '区县:', labelWidth: 50, key: 'districtName', attr: { canCopy: true } }
        ]).setWidth(120),
        BaseTableColumn.initData('address', '具体地址').setWidth(160).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('createTime', '创建时间').setWidth(160),
        BaseTableColumn.initData('communityTypeDesc', '创建类型').setSpan({}),
        BaseTableColumn.initData('h5StatusSwitch', '状态').setWidth(70).setSwitch((e, row, index) => {
          row.h5StatusSwitch = e;
          this.$Modal.confirm({
            title: '操作提示',
            content: `是否确认${e ? '启用' : '停用'}`,
            onOk: () => {
              this.requestChangeStatus(row, e);
            },
            onCancel: () => {
              row.h5StatusSwitch = !e;
              this.$Message.info('操作已取消');
            }
          });
        }, 'system:community:status'),
        BaseTableColumn.initData('setting', '操作').setWidth(100).setAlign('center').setButtons([
          { title: '行政区域', name: 0, permission: 'system:community:address:set' },
          { title: '物业组织设置', name: 1, permission: 'system:community:settleInfo:set' },
          { title: '联系人设置', name: 2, permission: 'system:community:contact:set' },
          { title: '编辑小区', name: 3, permission: 'system:community:add' }
        ], ({ name }, row, index) => {
          if (name === 3 && row.communityType === 1) {
            return this.$Message.warning('系统同步小区不可编辑');
          }
          if (name !== 3) {
            this.dialog.settingIndex = name;
          }
          this.requestDetail(row.communityId, name);
        })
      ]
    };
  }
};
