<!--
    Created by 程雨喵'mac on 2023/12/28.
    Copyright © 2023年 云柜-金星晖. All rights reserved.
    界面名称：
    功能概述：
-->
<style lang="less">
.block-setting-dialog {
  padding-bottom: @containerGap;
  height: 100%;
  .setting-inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    .setting-inner-header {
      display: flex;
      flex-wrap: wrap;
      font-size: 13px;
      box-sizing: border-box;
      line-height: 17px;
      background: @backColorNormal;
      border-radius: @borderRadiusMid;
      .setting-inner-header-row {
        .flex-grow(50%);
        &:nth-of-type(n+3) {
          padding-top: @containerGap * 0.5;
        }
      }
    }
    .setting-inner-box {
      flex: 1;
      margin-top: @containerGap;
      overflow: auto;
    }
    .setting-content-box {
      background-color: @backColorStrong;
      border-radius: 0 0 @borderRadiusMin @borderRadiusMin;
    }
  }
}
</style>

<template>
  <yg-modal
    :width="tab.index === 3 ? 700 : 600"
    title="设置"
    :back-type="2"
    sure-text="提交"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    :show-bottom="tab.index !== 3"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div
      slot="content"
      class="block-setting-dialog"
    >
      <div class="setting-inner">
        <div class="setting-inner-header">
          <div
            v-for="(item, i) in topInfos"
            :key="i"
            class="setting-inner-header-row"
          >
            <yg-attribute-key-value
              :label="item.label"
              :label-width="110"
              :value="model[item.key]"
              :can-copy="item.canCopy"
              :can-tooltip="!!item.canTooltip"
            />
          </div>
        </div>
        <div class="setting-inner-box">
          <yg-tab-primary
            :permission-items="tab.permissionItems"
            :index="tab.index"
            :items="tab.items"
            :type="2"
            @on-change="onTabChanged"
          />
          <div class="padding-primary setting-content-box">
            <v-operate
              v-if="tab.index === 0"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-orgnization
              v-else-if="tab.index === 1"
              ref="orgnazationRef"
              :model="model"
              @on-change="onChangeDisabled"
            />
            <v-contact
              v-else-if="tab.index === 2"
              :model="model"
              :need-code="false"
              @on-change="onChangeDisabled"
            />
          </div>
        </div>
      </div>
    </div>
  </yg-modal>
</template>

<script>
import CommunityApiSet from '@/request/api/CommunityApiSet';
import vContact from '@/pages/020__organizationManager/_components/contact.vue';
import vOperate from './operate.vue';
import vOrgnization from './orgnization.vue';

export default {
  components: { vContact, vOperate, vOrgnization },
  props: {
    index: {
      type: Number,
      default: 0
    },
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      topInfos: [
        { label: '小区名称：', key: 'communityName', canCopy: false, canTooltip: true },
        { label: '所属物业公司：', key: 'propertyCompanyName', canCopy: false, canTooltip: true },
        { label: '小区编码：', key: 'communityCode', canCopy: true, canTooltip: false },
        { label: '所属服务商：', key: 'serviceProviderName', canCopy: false, canTooltip: true }
      ],
      tab: {
        index: 0,
        items: ['行政区设置', '物业组织设置', '联系人设置'],
        permissionItems: ['system:community:address:set', 'system:community:settleInfo:set', 'system:community:contact:set']
      },
      buttonDisabled: true,
      buttonDisabledMsg: '',
      needRefreshList: false
    };
  },
  created () {
    console.log(this.model);
    this.tab.index = this.index;
  },
  methods: {
    onSure () {
      console.log(this.model);
      let res = {};
      const tIndex = this.tab.index;
      if (tIndex === 0) {
        // 行政区设置
        res = this.model.checkValidDistrictSubmit();
      } else if (tIndex === 1) {
        // 物业组织设置
        res = this.$refs.orgnazationRef.$checkValue();
      } else if (tIndex === 2) {
        // 联系人设置
        res = this.model.checkValidConcatSubmit();
      }
      if (!res.success) {
        this.$Message.warning(res.msg);
      } else {
        this.requestSubmit(tIndex, res.params);
      }
    },
    onClose () {
      this.$emit('on-close', this.needRefreshList);
    },
    onChangeDisabled ({ disabled, msg }) {
      this.buttonDisabled = disabled;
      this.buttonDisabledMsg = msg;
    },
    onTabChanged (e) {
      if (e === this.tab.index) return;
      this.buttonDisabled = false;
      this.buttonDisabledMsg = '';
      this.tab.index = e;
    },
    requestSubmit (type, params) {
      let api = {};
      if (type === 0) {
        api = CommunityApiSet.communityAddressSet;
      } else if (type === 1) {
        api = CommunityApiSet.settleInfoSet;
      } else if (type === 2) {
        api = CommunityApiSet.communityConcatSet;
      }
      if (!api.url) return;
      api.params = params;
      this.$http(api).then(res => {
        this.needRefreshList = type !== 1;
        if (type === 0) {
          // 提交后就不可编辑了
          this.model.h5AdminCanChange = false;
        }
        this.onChangeDisabled({ disabled: true, msg: '无数据变更' });
        this.$Message.success('操作成功');
      });
    }
  }
};
</script>
