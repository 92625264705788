import BaseModel from '@/libs/base/dataModel/BaseModel';
import { isPhone, isValidString1 } from '@/libs/regularUtils';

export class BlockListModel extends BaseModel {
  constructor () {
    super();
    this.communityName = ''; // 小区名称
    this.communityId = ''; // 小区编码
    this.communityCode = ''; // 小区编码
    this.contact = ''; // 联系人
    this.mobile = ''; // 联系人手机号
    this.propertyCompanyId = ''; // 所属物业公司id
    this.propertyCompanyName = ''; // 所属物业公司
    this.provinceName = ''; // 省份
    this.province = ''; // 省份ID
    this.serviceProviderId = ''; // 所属服务商ID
    this.serviceProviderName = ''; // 所属服务商
    this.cityName = ''; // 城市
    this.city = ''; // 城市ID
    this.address = ''; // 具体地址
    this.districtName = ''; // 区域
    this.district = ''; // 区域Id
    this.createTime = ''; // 创建时间
    this.status = ''; // 状态
    this.officeCode = ''; // 办事处code
    this.officeName = ''; // 办事处名称
    this.communityType = ''; // 小区类型:1->同步小区、2->自建小区
    this.communityTypeDesc = ''; // 小区类型:1->同步小区、2->自建小区
    // 详情中用
    this.communityPcOrg = {};
    // 前端自有
    this.h5StatusSwitch = false;
    this.h5Area = '';
    this.h5AreaArray = [];
  }

  initData (resData) {
    super.initData(resData);
    this.h5StatusSwitch = !!this.status;
    this.h5Area = (this.provinceName || '') + (this.cityName || '') + (this.districtName || '');
    this.h5AreaArray = [this.province || '', this.city || '', this.district || ''];
    return this;
  }

  /**
   * 数据具体校验-行政区设置
   * @returns {*}
   */
  checkValidDistrictSubmit () {
    const status = this.h5AreaArray.some(v => v === '');
    if (status || this.h5AreaArray.length !== 3) {
      return BaseModel.getValidRes(false, '请正确选择省市区');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.communityId,
      province: this.h5AreaArray[0],
      city: this.h5AreaArray[1],
      district: this.h5AreaArray[2],
      address: this.address
    });
  }

  /**
   * 数据具体校验-联系人
   * @returns {*}
   */
  checkValidConcatSubmit () {
    // 专项校验
    if (!isPhone(this.mobile)) {
      return BaseModel.getValidRes(false, '手机号码不合规，请重新输入');
    } else if (this.contact.length < 1 || this.contact.length > 20) {
      return BaseModel.getValidRes(false, '联系人请输入1-20位');
    } else if (!isValidString1(this.contact)) {
      return BaseModel.getValidRes(false, '联系人请输入有效字符');
    }
    return BaseModel.getValidRes(true, '', {
      ownerId: this.communityId,
      contact: this.contact,
      mobile: this.mobile,
      // captcha: this.code,
      uuid: ''
    });
  }

  /**
   * 数据具体校验-新增小区
   * @returns {*}
   */
  checkValidAdditionSubmit () {
    this.propertyCompanyId = this.propertyCompanyArry[0] || '';

    if (!this.propertyCompanyId) {
      return BaseModel.getValidRes(false, '请选择所属物业公司');
    }

    return BaseModel.getValidRes(true, '', {
      propertyCompanyId: this.propertyCompanyId,
      name: this.communityName
    });
  }

  /**
   * 数据具体校验-编辑小区
   * @returns {*}
   */
  checkValidEditSubmit () {
    return BaseModel.getValidRes(true, '', {
      propertyCompanyId: this.propertyCompanyId,
      name: this.communityName,
      id: this.communityId
    });
  }

  static createAdditionModel () {
    const model = new this();
    model.propertyCompanyArry = [];
    return model;
  }
}

export class BlockOrgnizationModel extends BlockListModel {
  constructor () {
    super();
    this.communityId = ''; // 小区ID
    this.propertyCompanyId = ''; // 物业公司ID
    this.pcOrgCode = ''; // 区域公司编码
    this.pcOrgName = ''; // 区域公司名称
    this.pcSapCode = ''; // 物业SAP编码
    this.pcSapName = ''; // 物业SAP名称
    this.pcSapBlockId = ''; // 新橙社BlockId
    this.xcsName = ''; // 新橙社名称
    this.officeCode = ''; // 所属办事处编码
    this.officeName = ''; // 所属办事处名称
    this.directorName = ''; // 办事处总监姓名
    this.directorTel = ''; // 办事处总监手机号
    this.settleCode = ''; // 物业结算code
    this.settleName = ''; // 物业结算名称
  }

  initData (resData) {
    super.initData(resData);
    return this;
  }

  /**
   * 数据具体校验-物业组织设置
   * @returns {*}
   */
  checkValidOperatingSubmit () {
    return BaseModel.getValidRes(true, '', {
      ownerId: this.communityId,
      settleCode: this.settleCode,
      settleName: this.settleName
    });
  }
}
