<!--
    Created by 王丽莎 on 2024/11/4.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    小区管理 - 编辑小区
-->
<style lang="less">
</style>

<template>
  <yg-modal
    title="编辑小区"
    width="500"
    :sure-button-disabled="buttonDisabled"
    :sure-button-disabled-text="buttonDisabledMsg"
    @on-sure="onSure"
    @on-close="onClose"
  >
    <div slot="content">
      <Form>
        <FormItem
          v-for="(item, i) in formItems"
          :key="i"
          :label="item.label + ':'"
          :label-width="item.labelWidth"
          :required="item.isRequired"
        >
          <div
            v-if="item.tagName === 'Div'"
          >
            {{ model[item.key] }}
          </div>
          <Input
            v-if="item.tagName === 'Input'"
            v-model.trim="model[item.key]"
            :placeholder="item.placeholder"
            class="display-flex-1"
            @input="checkSubmit"
          />
        </FormItem>
      </Form>
    </div>
  </yg-modal>
</template>

<script>

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      buttonDisabled: true,
      buttonDisabledMsg: '',
      formItems: [
        { label: '所属服务商', labelWidth: 145, placeholder: '', key: 'serviceProviderName', isRequired: false, tagName: 'Div' },
        { label: '所属物业公司', labelWidth: 145, placeholder: '', key: 'propertyCompanyName', isRequired: false, tagName: 'Div' },
        { label: '小区名称', labelWidth: 145, placeholder: '请输入6~40名称，可汉字、数字、字母或符号组合', key: 'communityName', isRequired: true, tagName: 'Input' }
      ]
    };
  },
  created () {
    console.log(this.model);
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    onSure () {
      this.$emit('on-sure');
    },
    onClose () {
      this.$emit('on-close');
    },
    checkSubmit () {
      const item = this.formItems.find(v => v.isRequired && !this.model[v.key]);
      this.buttonDisabled = !!item;
      this.buttonDisabledMsg = item ? (item.label + '不可为空') : '';
    }
  }
};
</script>
