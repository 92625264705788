<!--
    Created by 程雨喵'mac on 2024/1/2.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    经营设置 - 物业组织设置
-->
<style lang="less">
</style>

<template>
  <Form>
    <FormItem
      v-for="(item, i) in inputItems"
      :key="i"
      :label="item.label + ':'"
      :label-width="item.labelWidth"
      :required="item.isRequired"
    >
      <Input
        v-model.trim="organizationData[item.key]"
        :placeholder="item.placeholder"
        :disabled="item.disabled"
        class="display-flex-1"
        @input="checkSubmit"
      />
    </FormItem>
  </Form>
</template>

<script>

import CommunityApiSet from '@/request/api/CommunityApiSet';
import { BlockOrgnizationModel } from '@/pages/020__organizationManager/blockList/model/BlockDataModel';

export default {
  components: {},
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data () {
    return {
      inputItems: [
        { label: '所属办事处编码', labelWidth: 170, placeholder: '暂无数据', key: 'officeCode', isRequired: false, disabled: true },
        { label: '所属办事处名称', labelWidth: 170, placeholder: '暂无数据', key: 'officeName', isRequired: false, disabled: true },
        { label: '办事处总监姓名', labelWidth: 170, placeholder: '暂无数据', key: 'directorName', isRequired: false, disabled: true },
        { label: '办事处总监手机', labelWidth: 170, placeholder: '暂无数据', key: 'directorTel', isRequired: false, disabled: true },
        { label: '物业SAP项目编码', labelWidth: 170, placeholder: '暂无数据', key: 'pcSapCode', isRequired: false, disabled: true },
        { label: '物业SAP项目名称', labelWidth: 170, placeholder: '暂无数据', key: 'pcSapName', isRequired: false, disabled: true },
        { label: '物业SAP结算编码', labelWidth: 170, placeholder: '暂无数据', key: 'settleCode', isRequired: true, disabled: false },
        { label: '物业SAP结算名称', labelWidth: 170, placeholder: '暂无数据', key: 'settleName', isRequired: true, disabled: false },
        { label: '所属物业区域公司编码', labelWidth: 170, placeholder: '暂无数据', key: 'pcOrgCode', isRequired: false, disabled: true },
        { label: '所属物业区域公司名称', labelWidth: 170, placeholder: '暂无数据', key: 'pcOrgName', isRequired: false, disabled: true },
        { label: '新橙社blockID', labelWidth: 170, placeholder: '暂无数据', key: 'pcSapBlockId', isRequired: false, disabled: true },
        { label: '新橙社名称', labelWidth: 170, placeholder: '暂无数据', key: 'xcsName', isRequired: false, disabled: true }
      ],
      organizationData: {}
    };
  },
  created () {
    console.log(this.model.communityPcOrg);
    this.requestOrganization();
    this.$nextTick(() => {
      this.checkSubmit();
    });
  },
  methods: {
    checkSubmit () {
      const item = this.inputItems.find(v => v.isRequired && !this.organizationData[v.key]);
      this.$emit('on-change', {
        disabled: !!item,
        msg: item ? (item.label + '不可为空') : ''
      });
    },
    $checkValue () {
      return this.organizationData.checkValidOperatingSubmit();
    },
    // 获取物业组织详情
    requestOrganization () {
      const api = CommunityApiSet.getCommunityDetail;
      api.params = {
        communityId: this.model.communityId
      };
      console.log(api);
      this.$http(api).then(res => {
        this.organizationData = BlockOrgnizationModel.initModelWithData(res.data);
        this.organizationData.communityId = this.model.communityId;
      });
    }
  }
};
</script>
